@font-face {
    font-family: 'AvenirNextLTPro';
    src: url('assets/fonts/AvenirNextLTPro-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    background-color: #e4e8f3 !important;
    position: relative;
    min-height: 100%;
}

body {
    margin: 0 !important;
    font-family: "AvenirNextLTPro", sans-serif !important;
    font-size: 0.8125rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    text-align: left !important;
    background-color: #e4e8f3 !important;
    -webkit-text-size-adjust: 100% !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    overflow: hidden;
}

.form-control {
    padding: 0.47rem 0.75rem !important;
    font-size: .8125rem !important;
    border-color: var(--bs-border-color) !important;

    &:focus {
        color: #343a40;
        background-color: #fff;
        border-color: rgb(73, 80, 87, .2);
        outline: 0;
        box-shadow: none;
    }

}

.form-control.is-invalid {
    border-color: #f46a6a !important;

    &:focus {
        border-color: #f46a6a;
        box-shadow: 0 0 0 0.15rem rgba(244, 106, 106, .25);
    }
}


h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: calc(1.328125rem + .9375vw) !important
}

@media (min-width: 1200px) {

    h1,
    .h1 {
        font-size: 2.03125rem !important
    }
}

h2,
.h2 {
    font-size: calc(1.2875rem + .45vw) !important
}

@media (min-width: 1200px) {

    h2,
    .h2 {
        font-size: 1.625rem !important
    }
}

h3,
.h3 {
    font-size: calc(1.2671875rem + .20625vw) !important
}

@media (min-width: 1200px) {

    h3,
    .h3 {
        font-size: 1.421875rem !important
    }
}

h4,
.h4 {
    font-size: 1.21875rem !important
}

h5,
.h5 {
    font-size: 1.015625rem !important
}

h6,
.h6 {
    font-size: .8125rem !important
}